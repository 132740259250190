.vmz_banner{
	.list-default{
		padding: 15px 0;
		.banner-img{
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
		}
		.banner-title{
			display: inline-block;
			vertical-align: middle;
			@media screen and (max-width: 1024px) {
				font-size: 11px;
			}
			@media screen and (max-width: 768px) {
				font-size: 14px;
			}
			a{
				color: #333333;
				@media screen and (max-width: 1024px) {
					font-size: 11px;
				}
				@media screen and (max-width: 768px) {
					font-size: 13px;
				}
				&:hover{
					color: $color-main;
				}
			}
		}
	}
	.list-center{
		.banner-img{
			text-align: center;
			margin-bottom: 25px;
			img{
				max-width: 100%;
				height: auto;
			}
		}
		.banner-title{
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 15px;
			a{
				font-size: 18px;
				font-weight: bold;
			}
		}
	}
}