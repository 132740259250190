/* Website Style 1 */
.website-style-1 {
	.home-sidebar {
		@include media-max($md) {
			display: none;
		}
	}
	.main-home-content {
		@include media-max($md) {
			width: 100%;
		}
	}
	#colophon {
		padding-top: 40px;
		.footer-widget {
			.widget-title {
				position: relative;
				font-size: 14px;
				color: #333333;
				text-transform: uppercase;
				margin-bottom: 25px;
				padding-left: 0;
				&:after {
					background: $color-main;
					position: absolute;
					content: "";
					width: 20px;
					height: 2px;
					left: 0;
					bottom: -10px;
				}
			}
			ul {
				list-style: none;
				padding-left: 0;
				li {
					padding: 5px 0;
					a {
						&:hover {
							color: $color-main;
							cursor: pointer;
						}
					}
				}
			}
		}
		.bottom-footer {
			border-top: 1px solid $color-main;
			border-bottom: 1px solid $color-main;
			padding: 40px 0;
			.footer-tag {
				ul {
					padding: 0;
					margin: 0;
					list-style: none;
					li {
						display: inline-block;
						padding: 0 10px;
						a {
							color: #333333;
							position: relative;
							&:after {
								content: "";
								position: absolute;
								border-right: 1px solid #999999;
								right: -10px;
								top: 0;
								width: 1px;
								height: 100%;
							}
							&:hover {
								color: $color-main;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
	&.single {
		.vietmoz-title {
			font-size: 24px;
			margin: 0;
			margin-bottom: 15px;
			font-weight: 600;
			color: #333;
			border-bottom: 1px solid #ff3333;
			padding-bottom: 8px;
			&:hover {
				color: $color-main;
				cursor: pointer;
			}
		}
		.entry-meta {
			font-weight: 300;
		}
		.tags-list {
			a {
				background: #e6e6e6;
				padding: 0 10px;
				line-height: 20px;
				margin-right: 5px;
				display: inline-block;
			}
		}
	}
	&.category {
		.vietmoz-title {
			font-size: 24px;
			font-weight: 300;
			text-transform: uppercase;
			padding-bottom: 8px;
			margin-bottom: 15px;
			border-bottom: 1px solid #ff3333;
			&:hover {
				color: $color-main;
				cursor: pointer;
			}
		}
		article {
			padding: 15px 0 6px;
			border-top: $base-border;
		}
		.term-description {
			overflow: hidden;
			margin-bottom: 20px;
			h2 {
				font-size: 18px;
				color: #ff3333;
				margin-bottom: 20px;
				position: relative;
				padding-left: 24px;
				@media(max-width: 320px) {
					font-size: 16px;
					margin-bottom: 10px;
				}
				&::before {
					content: "";
					position: absolute;
					background: url(../images/icon-list.png) no-repeat;
					width: 13px;
					height: 14px;
					left: 0;
					top: 50%;
					transform: translate3d(0, -50%, 0);
				}
			}
			ul {
				padding: 0;
				margin: 0;
				-webkit-column-count: 3; /* Chrome, Safari, Opera */
				-moz-column-count: 3; /* Firefox */
				column-count: 3;
				@media(max-width: 414px) {
					-webkit-column-count: 2; /* Chrome, Safari, Opera */
					-moz-column-count: 2; /* Firefox */
					column-count: 2;
				}
				@media(max-width: 320px) {
					-webkit-column-count: 1; /* Chrome, Safari, Opera */
					-moz-column-count: 1; /* Firefox */
					column-count: 1;
				}
				li {
					list-style: none;
					a {
						color: #333;
						position: relative;
						padding-left: 15px;
						font-weight: 600;
						&::after {
							content: "";
							background: $color-main;
							width: 4px;
							height: 4px;
							position: absolute;
							left: 0;
							top: 50%;
							transform: translate3d(0, -50%, 0);
						}
						&:hover {
							cursor: pointer;
							color: $color-main;
						}
					}
				}
			}
		}
	}
	/* Widget Hot News */
	.vmz_hotnews {
		.style-default {
			float: left;
			width: 100%;
			margin-bottom: 20px;
			.item-large {
				float: left;
				width: 67.23%;
				@media (max-width: 736px) {
					width: 60%;
				}
				@media (max-width: 414px) {
					width: 100%;
					margin-bottom: 15px;
				}
				.img-featured {
					width: 100%;
					height: auto;
					margin-bottom: 15px;
					overflow: hidden;
					img {
						max-height: 347px;
						width: 100%;
					}
				}

				.title {
					font-size: 16px;
					font-weight: 600;
					color: #333;
					line-height: 24px;
					margin-bottom: 5px;
					display: block;
					&:hover {
						cursor: pointer;
						color: $color-main;
					}
				}
				.short-content {
					color: #666;
					font-weight: 350;
				}
			}
			.item-list {
				float: left;
				width: 32.77%;
				padding-left: 20px;
				.list-news {
					list-style: square;
					padding-left: 15px;
					margin: 0;
				}
				@media (max-width: 736px) {
					width: 40%;
				}
				@media (max-width: 414px) {
					width: 100%;
					padding-left: 0;
				}
				.text-head {
					font-size: 14px;
					background: #ff3333;
					color: #fff;
					font-weight: bold;
					text-transform: uppercase;
					padding-left: 10px;
					line-height: 20px;
					margin-bottom: 10px;
				}
				a {
					display: block;
					position: relative;
					color: #333;
					&:hover {
						color: $color-main;
					}
				}
			}
		}
	}
	/* Wiget Thumnail */
	.shortcode-thumnail {
		margin-left: -8px;
		margin-right: -8px;
		margin-bottom: 20px;
		.vc_col-sm-3 {
			.vc_column-inner {
				padding-left: 8px;
				padding-right: 8px;
			}
		}
	}
	.vmz_thumbnail {
		.thumbnail-img {
			margin-bottom: 8px;
			img {
				width: 100%;
				height: auto;
			}
			@include media-max($sm) {
				width: 40%;
				float: left;
				padding-right: 10px;
			}
		}
		.thumnail-content {
			width: 100%;
			@media (max-width: 320px) {
				display: block;
			}
			.title {
				padding-bottom: 6px;
				line-height: 18px;
				@media (max-width: 500px) {
					min-height: 60px;
				}
				a {
					color: #333;
					font-weight: 600;
					display: block;
					&:hover {
						text-decoration: none;
						cursor: pointer;
						color: $color-main;
					}
				}
			}
			.des {
				font-size: 13px;
				font-weight: 350;
				color: #666;
				@media (max-width: 414px) {
					display: none;
				}
			}
		}
	}
	/* Widget List Post By Category */
	.list-category-posts {
		.link-title {
			position: relative;
			.head-title {
				background-color: #f9f9f9;
				color: $color-main;
				font-weight: 400;
				font-size: 18px;
				padding: 7px 0;
				margin-bottom: 15px;
				text-transform: uppercase;
				border-top: 2px solid #ff3333;
				border-bottom: 1px solid #ccc;
				&:hover {
					color: $color-main;
					cursor: pointer;
				}
				a {
					color: $color-main;
					&:hover {
						color: #333;
						cursor: pointer;
					}
				}
				.ion-arrow-right-b {
					color: #ff3333;
					padding-left: 6px;
					@media (max-width: 500px) {
						display: none;
					}
				}
			}
			.link-custom {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translate3d(0, -50%, 0);
				@media (max-width: 568px) {
					display: none;
				}
				ul {
					list-style: none;
					padding: 0;
					margin: 0;

					li {
						display: inline-block;
						&:last-child {
							a {
								border-right: 0;
							}
						}
						a {
							color: #333;
							font-size: 13px;
							border-right: 1px solid #ccc;
							padding: 0 8px;
							@media (max-width: 667px) {
								padding: 0 5px;
							}
							&:hover {
								color: $color-main;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
	/* Widget Category Posts List */
	.widget_post_list {
		.list-post-item {
			background: #f9f9f9;
			padding: 0 10px 10px 20px;
			margin: 0;
		}
	}

	/* Single */
	.default-related-posts {
		h3 {
			background: #f9f9f9;
			font-size: 18px;
			color: $color-main;
			border-top: 2px solid #ff3333;
			border-bottom: 1px solid #ccc;
			line-height: 38px;
			text-transform: uppercase;
			margin: 0;
			margin-bottom: 15px;
		}
		.posts-list {
			-webkit-column-count: 3; /* Chrome, Safari, Opera */
			-moz-column-count: 3; /* Firefox */
			column-count: 3;
			li {
				position: relative;
				&:before {
					content: "";
					background: #0342ac;
					width: 4px;
					height: 4px;
					position: absolute;
					left: 0;
					top: 10px;
				}
				a {
					color: #333;
					position: relative;
					padding-left: 10px;
					display: block;
					&:hover {
						color: $color-main;
						cursor: pointer;
					}
				}
			}
		}
	}
	.vmz-mega-menu {
		&.menu-mega-has-children {
			padding-right: $gutter-width/2 + 10px;
			@include media-max($sm) {
				padding-right: 0;
			}
		}
		> a {
			position: relative;
			&:after {
				content: "\f3d0";
				font-family: Ionicons;
				position: absolute;
				right: -25px;
				top: 50%;
				transform: translateY(-50%);
				color: #ffffff;
			}
		}
		.megamenu {
			background: #ffffff;
			position: absolute;
			width: 100%;
			float: left;
			left: 0;
			margin: 0;
			border: 1px solid $color-border;
			box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
			display: none;
			.tab-content {
				display: none;
			}
			.tab-show {
				display: block;
			}
			.tab-titles {
				background: #fafafa;
				width: 218px;
				max-width: 218px;
				float: left;
				text-align: right;
				padding: 22px 0 16px 0;
				overflow-x: hidden;
				overflow-y: auto;
				max-height: 252px;
				position: relative;
				li {
					color: #333;
					border-top: 1px solid transparent;
					border-bottom: 1px solid transparent;
					padding: 5px 22px;
					a {
						line-height: 25px;
					}
					&:hover {
						background: #ffffff;
						border-top: 1px solid $color-border;
						border-bottom: 1px solid $color-border;
						a {
							background: none;
						}
					}
				}
			}
			.right-content {
				float: left;
				padding: 22px 0 16px 0;
				width: calc(100% - 218px);
				.item-post {
					width: 202px;
					min-height: 202px;
					float: left;
					margin-left: 22px;
					.img-item {
						img {
							width: 100%;
							height: auto;
						}
					}
				}
				.item-post-cat {
					display: none;
					&.active {
						display: block;
					}
				}
			}
		}
	}
}
