.vmz-slider{
	.slick-arrow{
		z-index: 999;
		&::before{
			font-family: $io;
			font-size: 35px;
			color: rgba(#ffffff,0.6);
		}
		&.slick-next{
			right: 20%;
			&::before{
				content:"\f125";
			}
		}
		&.slick-prev{
			left: 20%;
			&::before{
				content:"\f124";
			}
		}
	}
}