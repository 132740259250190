.vmz_banner .list-default {
  padding: 15px 0;
}

.vmz_banner .list-default .banner-img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.vmz_banner .list-default .banner-title {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 1024px) {
  .vmz_banner .list-default .banner-title {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .vmz_banner .list-default .banner-title {
    font-size: 14px;
  }
}

.vmz_banner .list-default .banner-title a {
  color: #333333;
}

@media screen and (max-width: 1024px) {
  .vmz_banner .list-default .banner-title a {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .vmz_banner .list-default .banner-title a {
    font-size: 13px;
  }
}

.vmz_banner .list-default .banner-title a:hover {
  color: #ff4064;
}

.vmz_banner .list-center .banner-img {
  text-align: center;
  margin-bottom: 25px;
}

.vmz_banner .list-center .banner-img img {
  max-width: 100%;
  height: auto;
}

.vmz_banner .list-center .banner-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.vmz_banner .list-center .banner-title a {
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 479px) {
  .vmz_listcat .list-cat-shortcode .item {
    width: 100%;
  }
}

.vmz_listcat .item-cat {
  background: #ffffff;
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}

@media screen and (max-width: 479px) {
  .vmz_listcat .item-cat {
    margin-bottom: 10px;
  }
}

.vmz_listcat .item-cat .img-item {
  margin-bottom: 20px;
}

@media screen and (max-width: 479px) {
  .vmz_listcat .item-cat .img-item {
    margin-bottom: 10px;
  }
}

.vmz_listcat .item-cat .img-item img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 479px) {
  .vmz_listcat .item-cat .img-item img {
    width: 100%;
  }
}

.vmz_listcat .item-cat .content-item .cat-title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #333333;
  padding-bottom: 15px;
  display: block;
  text-align: center;
}

.vmz_listcat .item-cat .content-item .excerpt {
  padding: 0 10px 30px 15px;
}

@media screen and (max-width: 479px) {
  .vmz_listcat .item-cat .content-item .excerpt {
    padding: 0 10px 10px;
  }
}

.vmz_listcat .item-cat .detail-cat {
  text-align: center;
  margin-bottom: 35px;
}

@media screen and (max-width: 479px) {
  .vmz_listcat .item-cat .detail-cat {
    margin-bottom: 20px;
  }
}

.vmz_listcat .item-cat .detail-cat a {
  background: #ff4064;
  color: #ffffff;
  font-size: 16px;
  width: 126px;
  height: 42px;
  display: block;
  text-align: center;
  line-height: 42px;
  margin: auto;
  border: 1px solid transparent;
  border-radius: 5px;
}

.vmz_listcat .item-cat .detail-cat a:hover {
  background: #04662b;
}

.list-item-home {
  width: 100%;
  clear: both;
  margin-bottom: 20px;
  overflow: hidden;
}

.list-item-home .left-content {
  float: left;
  width: 42.5%;
}

@media (max-width: 414px) {
  .list-item-home .left-content {
    width: 100%;
    margin-bottom: 15px;
  }
}

.list-item-home .left-content .img-home {
  margin-bottom: 10px;
  display: block;
}

@media (max-width: 414px) {
  .list-item-home .left-content .img-home img {
    width: 100%;
  }
}

.list-item-home .left-content .post-large-content {
  width: 100%;
}

.list-item-home .left-content .post-large-content .title {
  margin-bottom: 10px;
}

.list-item-home .left-content .post-large-content .title a {
  font-size: 16px;
  color: #333;
  display: block;
  line-height: 20px;
  font-weight: 600;
}

.list-item-home .left-content .post-large-content .title a:hover {
  color: #ff4064;
}

.list-item-home .left-content .post-large-content .excerpt {
  color: #666;
  font-size: 13px;
}

@media (max-width: 1024px) {
  .list-item-home.style-default .left-content .post-large-content .excerpt {
    display: none;
  }
}

.list-item-home.style-list .item {
  width: 100%;
  float: left;
  margin-bottom: 65px;
}

@media screen and (max-width: 479px) {
  .list-item-home.style-list .item {
    margin-bottom: 15px;
  }
}

.list-item-home.style-list .item .img-item {
  width: 39.6%;
  float: left;
  margin-right: 15px;
}

.list-item-home.style-list .item .content-item {
  width: 56%;
  float: left;
}

.list-item-home.style-list .item .content-item .post-title {
  display: block;
  font-size: 16px;
  color: #333333;
  text-transform: uppercase;
}

.list-item-home.style-list .item .content-item .post-title:hover {
  color: #ff4064;
}

@media screen and (max-width: 479px) {
  .list-item-home.style-list .item .content-item .excerpt {
    display: none;
  }
}

.list-item-home.style-onecolumn .item {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.list-item-home.style-onecolumn .item .img-item {
  width: 25.6%;
  float: left;
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .list-item-home.style-onecolumn .item .img-item {
    margin-right: 15px;
  }
}

.list-item-home.style-onecolumn .item .content-item {
  width: 70%;
  float: left;
}

.list-item-home.style-onecolumn .item .content-item .post-title {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 5px;
}

.list-item-home.style-onecolumn .item .content-item .post-title:hover {
  color: #ff4064;
}

.list-item-home.style-onecolumn .item .content-item .entry-meta .post-categories {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0;
}

@media screen and (max-width: 479px) {
  .list-item-home.style-onecolumn .item .content-item .excerpt {
    display: none;
  }
}

.list-item-home.style-twocolumn .row-column {
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
}

.list-item-home.style-twocolumn .row-column .item-twocolumn {
  float: left;
  width: 50%;
}

.list-item-home.style-twocolumn .row-column .item-twocolumn .img-home {
  padding-bottom: 10px;
  display: block;
}

.list-item-home.style-twocolumn .row-column .item-twocolumn .title a {
  color: #333;
  font-weight: 600;
}

.list-item-home.style-twocolumn .row-column .item-twocolumn .title a:hover {
  color: #ff4064;
}

.list-item-home.style-twocolumn .row-column .item-twocolumn.item-1 {
  padding-right: 15px;
}

.list-item-home.style-twocolumn .row-column .item-twocolumn.item-2 {
  padding-left: 15px;
}

.list-item-home.style-twocolumn .title-column {
  position: relative;
  padding-left: 15px;
}

.list-item-home.style-twocolumn .title-column:hover::before {
  color: #ff4064;
}

.list-item-home.style-twocolumn .title-column::before {
  content: "\f24f";
  font-size: 6px;
  font-family: Ionicons;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  color: #ccc;
}

.list-item-home.style-twocolumn .title-column a {
  color: #333;
  display: block;
}

.list-item-home.style-twocolumn .title-column a:hover {
  color: #ff4064;
}

.list-item-home.style-twocolumn .post-large-content .excerpt {
  display: none;
}

@media (max-width: 1024px) {
  .list-item-home.style-twocolumn .post-large-content .excerpt {
    display: block;
  }
}

@media screen and (max-width: 479px) {
  .list-item-home.style-threecolumn .item {
    width: 100%;
  }
}

.list-item-home.style-threecolumn .item-threecolumn {
  border: 1px solid #f2f2f2;
}

@media screen and (max-width: 479px) {
  .list-item-home.style-threecolumn .item-threecolumn {
    margin-bottom: 15px;
  }
}

.list-item-home.style-threecolumn .item-threecolumn .img-home {
  margin-bottom: 15px;
  display: block;
}

.list-item-home.style-threecolumn .item-threecolumn .img-home img {
  max-width: 100%;
  height: auto;
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content {
  padding: 0 10px 70px 25px;
}

@media screen and (max-width: 479px) {
  .list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content {
    padding: 0 10px;
  }
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content .title {
  text-algin: center;
  padding-bottom: 10px;
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content .title a {
  color: #646464;
  font-size: 18px;
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content .excerpt {
  padding-bottom: 15px;
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content .poster-on {
  color: #cccccc;
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content .poster-on .view {
  padding-right: 7px;
  border-right: 1px solid #cccccc;
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content .poster-on .comment {
  padding-left: 7px;
}

.list-item-home.style-threecolumn .item-threecolumn .item-threecolumn-content .poster-on .comment.ion-chatboxes:before {
  margin-right: 5px;
}

.list-item-home .right-content {
  float: left;
  width: 57.5%;
  padding-left: 30px;
}

@media (max-width: 414px) {
  .list-item-home .right-content {
    width: 100%;
    padding-left: 0;
  }
}

.list-item-home .right-content .post-item {
  width: 100%;
  clear: both;
  overflow: hidden;
  margin-bottom: 15px;
}

.list-item-home .right-content .post-item .img-home {
  float: left;
  display: inline-block;
  padding-right: 20px;
  width: 37.7%;
}

@media (max-width: 414px) {
  .list-item-home .right-content .post-item .img-home {
    width: 47%;
  }
}

.list-item-home .right-content .post-item .post-item-content {
  float: left;
  display: inline-block;
  width: 62.3%;
}

@media (max-width: 414px) {
  .list-item-home .right-content .post-item .post-item-content {
    width: 53%;
  }
}

.list-item-home .right-content .post-item .post-item-content .title a {
  font-weight: 600;
  color: #333;
  padding-bottom: 5px;
  line-height: 18px;
}

@media (max-width: 320px) {
  .list-item-home .right-content .post-item .post-item-content .title a {
    font-size: 13px;
  }
}

.list-item-home .right-content .post-item .post-item-content .title a:hover {
  color: #ff4064;
}

.list-item-home .right-content .post-item .post-item-content .excerpt {
  color: #666;
  font-size: 13px;
}

@media (max-width: 1024px) {
  .list-item-home .right-content .post-item .post-item-content .excerpt {
    display: none;
  }
}

.vmz_img_text .content-text-img .content-img {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}

@media screen and (max-width: 1028px) {
  .vmz_img_text .content-text-img .content-img {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .vmz_img_text .content-text-img .content-img {
    display: none;
  }
}

.vmz_img_text .content-text-img .content-text {
  display: inline-block;
  vertical-align: top;
  counter-reset: item;
}

.vmz_img_text .content-text-img .content-text ul {
  list-style: none;
}

.vmz_img_text .content-text-img .content-text ul li {
  counter-increment: item;
  padding-left: 70px;
  margin-bottom: 35px;
  position: relative;
  font-weight: bold;
}

@media screen and (max-width: 479px) {
  .vmz_img_text .content-text-img .content-text ul li {
    font-size: 12px;
    padding-left: 35px;
    margin-bottom: 20px;
  }
}

.vmz_img_text .content-text-img .content-text ul li:before {
  position: absolute;
  content: counter(item);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

@media screen and (max-width: 479px) {
  .vmz_img_text .content-text-img .content-text ul li:before {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}

.vmz_img_text .content-text-img.style-default .content-img {
  margin-right: 55px;
}

.vmz_img_text .content-text-img.style-default .content-text {
  width: 44.5%;
}

@media screen and (max-width: 768px) {
  .vmz_img_text .content-text-img.style-default .content-text {
    width: 100%;
  }
}

.vmz_img_text .content-text-img.style-default .content-text ul li:before {
  background: #ffffff;
  border: 1px solid #ff4064;
  color: #ff4064;
}

.vmz_img_text .content-text-img.style-float-right {
  background: #ffffff;
}

.vmz_img_text .content-text-img.style-float-right .content-text {
  width: 50.7%;
  padding-left: 30px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .vmz_img_text .content-text-img.style-float-right .content-text {
    width: 100%;
    padding: 0;
  }
}

.vmz_img_text .content-text-img.style-float-right .content-text ul li:before {
  background: #ff4064;
  color: #ffffff;
}

.vmz-slider .slick-arrow {
  z-index: 999;
}

.vmz-slider .slick-arrow::before {
  font-family: Ionicons;
  font-size: 35px;
  color: rgba(255, 255, 255, 0.6);
}

.vmz-slider .slick-arrow.slick-next {
  right: 20%;
}

.vmz-slider .slick-arrow.slick-next::before {
  content: "\f125";
}

.vmz-slider .slick-arrow.slick-prev {
  left: 20%;
}

.vmz-slider .slick-arrow.slick-prev::before {
  content: "\f124";
}

.vmz_list_icon {
  display: flex;
}

@media screen and (max-width: 767px) {
  .vmz_list_icon {
    display: block;
  }
}

.vmz_list_icon .icon {
  width: 20%;
  border: 1px solid #ebebeb;
  margin-right: 20px;
  text-align: center;
  padding: 29px 0;
}

@media screen and (max-width: 767px) {
  .vmz_list_icon .icon {
    width: 33.3%;
    float: left;
    margin-right: -1px;
    margin-bottom: -1px;
  }
}

.vmz_list_icon .icon img {
  max-width: 100%;
  height: auto;
  padding-bottom: 7px;
  max-height: 54px;
}

.vmz_list_icon .icon p {
  margin: 0;
  font-size: 16px;
  color: #333333;
}

@media screen and (max-width: 767px) {
  .vmz_list_icon .icon p {
    min-height: 48px;
  }
}

.list-brands .item-brand .item-wapper {
  margin-right: 23px;
  text-align: center;
}

.list-brands .item-brand .item-wapper .img-item {
  margin-bottom: 20px;
}

.list-brands .item-brand .item-wapper .img-item img {
  border: 1px solid #acacac;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.list-brands .item-brand .item-wapper .brand-title a {
  color: #333333;
}

.list-brands .item-brand .item-wapper .brand-title a:hover {
  color: #ff4064;
}

.list-brands-no-slider {
  width: 100%;
  overflow: hidden;
  clear: both;
}

.list-brands-no-slider .item-brand {
  width: 33.3%;
  border-right: 1px solid #ebebeb;
  float: left;
  padding: 0 15px;
}

@media screen and (max-width: 479px) {
  .list-brands-no-slider .item-brand {
    width: 100%;
  }
}

.list-brands-no-slider .item-brand:nth-child(3n) {
  border-right: 0;
}

.list-brands-no-slider .item-brand .img-item {
  text-align: center;
}

.list-brands-no-slider .item-brand .brand-title {
  text-align: center;
  min-height: 54px;
  margin-bottom: 10px;
}

.list-brands-no-slider .item-brand .brand-title a {
  color: #333333;
  font-size: 24px;
}

.list-brands-no-slider .item-brand .address-customer {
  min-height: 48px;
  color: #ff4064;
  text-align: center;
  margin-bottom: 5px;
}

.style-border_button .btn {
  background: #ffffff;
  border: 1px solid #ebebeb;
}

div[data-animation-enable="1"] {
  opacity: 0;
}

div[data-animation-enable="1"].animated {
  opacity: 1;
}
