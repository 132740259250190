.vmz_img_text{
	.content-text-img{
		.content-img{
			display: inline-block;
			vertical-align: top;
			width: 49%;
			@media screen and (max-width: 1028px){
				width: 48%;
			}
			@media screen and (max-width: 768px){
				display: none;
			}
		}
		.content-text{
			display: inline-block;
			vertical-align: top;
			counter-reset: item;
			ul{
				list-style: none;
				li{
					counter-increment: item;
					padding-left: 70px;
					margin-bottom: 35px;
					position: relative;
					font-weight: bold;
					@include media-max($xs){
						font-size: 12px;	
						padding-left: 35px;
						margin-bottom: 20px;
					}
					&:before{
						position: absolute;
						content: counter(item);
						border-radius: 100%;
						width: 40px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						left: 0;
						top: 50%;
						transform: translate3d(0,-50%,0);
						
						@include media-max($xs){
							margin-right: 5px;
							width: 25px;
							height: 25px;
							line-height: 25px;
						}
					}
				}
			}
		}
		&.style-default{
			.content-img{
				margin-right: 55px;
			}
			.content-text{
				width: 44.5%;
				@media screen and (max-width: 768px){
					width: 100%;
				}
				ul{
					li{
						&:before{
							background: #ffffff;
							border: 1px solid $color-main;
							color: $color-main;
						}
					}
				}
			}
		}
		&.style-float-right{
			background: #ffffff;
			.content-text{
				width: 50.7%;
				padding-left: 30px;
				padding-right: 20px;
				@media screen and (max-width: 768px){
					width: 100%;
					padding: 0;
				}
				ul{
					li{
						&:before{
							background: $color-main;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}
