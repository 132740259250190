.list-brands{
	.item-brand{
		.item-wapper{
			margin-right: 23px;
			text-align: center;
			.img-item{
				margin-bottom: 20px;	
				img{
					border: 1px solid #acacac;
					border-radius: 5px;
					max-width: 100%;
					height: auto;
				}
			}
			.brand-title{
				a{
					color: #333333;
					&:hover{
						color: $color_main;
					}
				}
			}
		}
	}
}
.list-brands-no-slider{
	width: 100%;
	overflow: hidden;
	clear: both;
	.item-brand{
		width: 33.3%;
		border-right: 1px solid $color_border;
		float: left;
		padding: 0 15px;
		@include media-max($xs){
			width: 100%;
		}
		&:nth-child(3n){
			border-right: 0;
		}
		.img-item{
			text-align: center;
		}
		.brand-title{
			text-align: center;
			min-height: 54px;
			margin-bottom: 10px;
			a{
				color: #333333;
				font-size: 24px;
			}
		}
		.address-customer{
			min-height: 48px;
			color: $color_main;
			text-align: center;
			margin-bottom: 5px;
		}
	}
}