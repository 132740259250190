.top-bar {
	background: map-deep-get($color, top-bar, bg);
	line-height: 38px;
	height: 38px;
	color: map-deep-get($color, top-bar, text);
	.insider {
		display: flex;
		justify-content: space-between;
	}
	.right-content {
		margin-left: auto;
	}
	@include media-max($md) {
		display: none;
	}
	span {
		display: inline-block;
	}
	.divider {
		height: 100%;
		width: 1px;
		background: $color-border;
		vertical-align: top;
		margin: 0 20px 0 20px;
		display: inline-block;
	}
	a {
		color: map-deep-get($color, top-bar, link);
		&:hover,
		&:focus,
		&:hover:focus {
			color: map-deep-get($color, top-bar, link-hover);
		}
	}
}

#masthead {
	position: relative;
	z-index: 10;
	background-color: $bg-primary;
	@include media-max($md) {
		display: none;
	}
	.header-box-shadow & {
		box-shadow: $alt-box-shadow;
	}
}

.site-title {
	margin: 0;
	line-height: 1;
	> * {
		line-height: 1;
	}
	img {
		display: block;
	}
}

#mhead {
	position: relative;
	z-index: 10;
	background-color: $bg-primary;
	@include media($md) {
		display: none;
	}
	.insider {
		height: px($header-m-height);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.header-box-shadow & {
		box-shadow: $alt-box-shadow;
	}
}

.menu-toggler {
	position: relative;
	text-indent: -9999px;
	padding: 0 px($gutter-width/2);
	margin-left: px(-$gutter-width/2);
	height: 100%;
	width: px($header-m-height);
	font-size: 0;
	cursor: pointer;
	display: block;
	-webkit-tap-highlight-color: transparent;
}

.menu-toggler.simple {
	&:before {
		@include align(lc);
		content: "\f20e";
		font-family: "Ionicons";
		color: $color-text;
		display: block;
		text-indent: 0;
		left: px($gutter-width/2);
		font-size: 26px;
	}
}

.menu-toggler.apple {
	transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	cursor: pointer;
	&:before,
	&:after {
		position: absolute;
		top: 50%;
		content: '';
		display: block;
		height: 1px;
		width: 17px;
		background: #000;
		transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	}
	&:before {
		transform: translate3d(0, -3px, 0);
	}
	&:after {
		transform: translate3d(0, 3px, 0);
	}
	.active & {
		transform: rotate(90deg);
		&:before {
			transform: translate3d(0, 0, 0) rotate(45deg);
			transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
		}
		&:after {
			transform: translate3d(0, 0, 0) rotate(-45deg);
			transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
		}
	}
}

.search-toggler {
	position: relative;
	text-indent: -9999px;
	padding: 0 px($gutter-width/2);
	margin-right: px(-$gutter-width/2);
	height: 100%;
	width: px($header-m-height);
	font-size: 0;
	cursor: pointer;
	display: block;
	transition: $base-transition;
	-webkit-tap-highlight-color: transparent;
	&:before {
		@include align(rc);
		content: "\f4a5";
		font-family: "Ionicons";
		color: $color-text;
		display: block;
		text-indent: 0;
		right: px($gutter-width/2);
		font-size: 18px;
	}
	.active & {
		transition: $base-transition;
		transform: translateY(-100%);
	}
	.search-active &:before {
		content: "\f404";
		font-size: 30px;
	}
}

#search-holder {
	position: relative;
	#search-wrapper {
		display: none;
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		background-color: map-deep-get($color, bg, body);
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
	}
	form {
		margin-top: $gutter-width/2;
		margin-bottom: $gutter-width/2;
	}
}

#masthead.headroom,
#mhead.headroom {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
	will-change: transform;
	transition: transform 200ms linear;
}

.headroom--pinned {
	transform: translateY(0%);
	box-shadow: $alt-box-shadow;
	&.headroom--top {
		box-shadow: none;
		.header-box-shadow & {
			box-shadow: $alt-box-shadow;
		}
	}
}

.headroom--unpinned {
	transform: translateY(-100%);
}

.headroom-enabled #page {
	padding-top: px($header-height);
}

.m-headroom-enabled #page {
	@include media-max($md) {
		padding-top: px($header-m-height);
	}
}

/* Menu */
#m-menu {
	background-color: $bg-primary;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

.vietmoz-menu {
	margin: 0;
	font-size: 0;
	li {
		position: relative;
		@include hover {
			> a, &:after {
				color: $color-main;
			}
		}
	}
	a{
		color: $color-text;
	}
	> li {
		@include typo(map-deep-get($typo, menu));
		display: inline-block;
		padding: 0 px($gutter-width/2);
		vertical-align: top;
		> a {
			display: block;
		}
		&.menu-item-has-children {
			&:after {
				content: "\f3d0";
				font-family: Ionicons;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				color: $color-text;
			}
		}
		&.home {
			> a {
				font-size: 0;
				&:after {
					content: "\f38f";
					font-family: Ionicons;
					display: inline-block;
					font-size: 20px;
					color: #fff;
					line-height: 1;
					vertical-align: middle;
				}
			}
		}
	}
	ul {
		margin: 0;
		display: none;
		position: absolute;
		background-color: $bg-primary;
		border: $base-border;
		right: 0;
		li {
			width: 250px;
			display: block;
			&.menu-item-has-children {
				&:after {
					content: "\f3d3";
					font-family: Ionicons;
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			&:hover {
				> a {
					background: $bg-secondary;
				}
			}
		}
		a {
			@include typo(map-deep-get($typo, submenu));
			padding: 0 $base-spacing;
			display: block;
			border-bottom: $base-border;
		}
	}
	ul ul {
		right: 100%;
		top: -1px;
	}
	li.menu-item-has-children:hover {
		> ul {
			display: block;
		}
	}
}

.vietmoz-m-menu {
	margin-bottom: $base-spacing;
	margin-top: $small-spacing;
	li {
		position: relative;
		&:hover,
		&:focus {
			> a {
				color: $color-main;
			}
		}
	}
	a {
		color: $color-text;
	}
	> li {
		opacity: 0;
		transform: scale(1.1) translateY(-24px);
		transition: opacity 0.35s ease-out, transform 0.35s ease-out;
		transition-delay: 400ms, 400ms;
		.active & {
			opacity: 1;
			transform: none;
		}
		@for $i from 1 through 20 {
			.active &:nth-child(#{$i}) {
				transition-delay: 200ms + $i * 50ms, 200ms + $i * 50ms;
			}
		}
	}
	a {
		display: block;
		border-bottom: $base-border;
		@include typo(map-deep-get($typo, submenu));
	}
	ul {
		display: none;
	}
	ul a {
		padding-left: $base-spacing;
	}
	ul ul a {
		padding-left: $base-spacing * 2;
	}
	ul ul ul a {
		padding-left: $base-spacing * 3;
	}
	ul ul ul ul a {
		padding-left: $base-spacing * 4;
	}
	ul ul ul ul ul a {
		padding-left: $base-spacing * 5;
	}
}

.dropdown-toggler {
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	display: none;
	text-align: center;
	width: px(map-deep-get($typo, submenu, line-height));
	height: px(map-deep-get($typo, submenu, line-height));
	cursor: pointer;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	outline: 0;
	.vietmoz-m-menu & {
		display: block;
	}
	&:focus {
		outline: 0;
	}
	&:before {
		content: "\f489";
		font-family: Ionicons;
		font-size: 20px;
		line-height: px(map-deep-get($typo, submenu, line-height));
		display: inline-block;
		vertical-align: top;
		transition: $base-transition;
	}
	li.dropdown-active > & {
		&:before {
			transform: rotate(45deg);
		}
	}
}

#nav-holder {
	position: relative;
	z-index: 1;
}

#m-menu {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	display: none;
}

header[class*="default-header"] {
	.insider {
		height: px($header-height);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.vietmoz-menu > li > a {
		line-height: px($header-height);
	}
}

header[class*="header-v1"] {
	.insider {
		height: px($header-height);
		display: flex;
		align-items: center;
		justify-content: space-between;
		.right-content {
			text-align: right;
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
	.vietmoz-menu {
		> li > a {
			line-height: px($header-menu-height);
		}
	}
}

.header-v1 {
	.main-menu {
		margin-left: -$gutter-width/2;
		margin-right: -$gutter-width/2;
	}
	.vietmoz-menu {
		ul {
			left: 0;
			right: auto;
		}
		ul ul {
			left: 100%;
			right: auto;
		}
	}
}

.header-v1-alt {
	.main-menu {
		background: $color-main;
		margin-left: 0;
		margin-right: 0;
	}
	.vietmoz-menu {
		ul {
			left: 0;
			right: auto;
		}
		ul ul {
			left: 100%;
			right: auto;
		}
		> li {
			> a {
				color: #ffffff;
			}
			&:hover {
				> a {
					color: #ffffff;
				}
				background: shade($color-main, 10%);
			}
			&.menu-item-has-children {
				padding-right: $gutter-width/2 + 10px;
				&:after {
					color: #ffffff;
					right: 5px;
				}
			}
		}
	}
}

.header-v1-alt2 {
	border-bottom: 1px solid $color-main;
	.vietmoz-menu {
		> li {
			padding: 0 20px;
			&:hover,
			&.current-menu-item {
				background: $color-main;
				> a {
					color: #ffffff;
				}
			}
		}
	}
}