.mc4wp-form-fields {
	position: relative;
	@include clearfixall;
	input {
		display: block;
		margin-bottom: 0;
		line-height: 38px !important;
		padding-top: 0;
		padding-bottom: 0;
	}
	input[type="email"] {
		width: calc( 100% - 120px );
		float: left;
	}
	input[type="submit"] {
		float: right;
		width: 100px;
		position: absolute;
		top: 0;
		right: 0;
	}
}