.error404 {
	#masthead,
	#colophon,
	.title-bar,
	.copyright {
		display: none;
	}
	#primary {
		background: $bg-primary;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		text-align: center;
		display: flex;
		align-content: center;
		align-items:center;
		justify-content: center;
		width: 100%;
		z-index: 20;
	}

	.error-404-icon {
		@extend h1;
		font-weight: bold;
		margin-bottom: 40px;
		color: $color-text;
		font-size: modular-scale( 9 );
	}
	.back-to-home {
		a {
			font-weight: bold;
		}
	}
}