.website-style-4{
	.wpb_content_element{
		margin-bottom: 0;
	}
	.box-border{
		.vc_column-inner{
			>.wpb_wrapper{
				padding: 15px;
				background: #ffffff;
				border: 1px solid $color_main;
				min-height: 215px;
				@include media-max($sm){
					min-height: 180px;
				}
			}
		}
	}
	.box-border-item{
		.border{
			@include media-max($sm){
				width: 50%;
				float: left;
			}
			img{
				border: $base_border;
				margin-bottom: -1px;
			}
		}
	}
	.vietmoz-menu{
		>li.link-register{	
			margin-top: 15px;
			a{
				background: $color_main;
				line-height: 34px;	
				color: #ffffff;
				padding: 0 8px;
				&:hover{
					background: #ff9900;
				}
			}	
		}
	}
	
	.custom-menu{
		background: $color_main;
		margin-bottom: 20px;
		.second-menu{
			margin: 0;
			padding: 0;
			>li{
				display: inline-block;
				padding: 0 25px;
				@include media-max($sm){
					padding: 0 15px;
				}
				&:first-child{
					padding-left: 0;
				}
				a{
					display: block;
					line-height: 50px;
					color: #ffffff;
				}
				&.current-menu-item{
					background: rgba(#ffffff, 0.1);
					border-top: 2px solid #ffffff;
				}
			}
		}
	}
	.form-page-register{
		form{
			input[type="submit"]{
				border-radius: 0;
				width: 193px;
				height: 56px;
				text-transform: uppercase;
				font-size: 18px;
				font-weight: bold;
				margin-top: 25px;
				float: right;
			}
		}
	}
	.archive-style-1{
		article{
			margin-bottom: 25px;
			padding-bottom: 25px;
			border-bottom: $base_border;
			&::last-child{
				border-bottom: 0;
			}
			@include media-max($sm){
				margin-bottom: 15px;
				padding-bottom: 15px;
			}
			.post-thumbnail{
				margin-bottom: 0;
			}
		}
	}
	#secondary{
		.widget{
			.widget-title{
				background: $color_main;
				margin-bottom: 5px;
				font-size: 16px;
				text-transform: uppercase;
				color: #ffffff;
				line-height: 40px;
				padding-left: 15px;
			}
		}
	}
	.list-true{
		&.list-true-left{
			ul{
				li{
					text-align: right;
					padding-right: 35px;
					@include media-max($sm){
						text-align: left;
						padding-right: 0;
						padding-left: 35px;
					}
					&::before{
						right: 0;
						top: 50%;
						transform: translate3d(0,-50%,0);
						@include media-max($sm){
							left: 0;
						}
					}
				}
			}
		}
		&.list-true-right{
			ul{
				li{
					padding-left: 35px;
					&::before{
						left: 0;
						top: 50%;
						transform: translate3d(0,-50%,0);
					}
				}
			}
		}
		ul{
			li{
				position: relative;
				line-height: 30px;
				&::last-child{
					padding-bottom: 0;
				}
				&::before{
					font-family: $io;
					content: '\f375';
					position: absolute;
					font-size: 24px;
					color: $color_main;
				}
				
			}
		}
	}
	.vietmoz-related-posts{
		h3{
			background: $color_main;
			font-size: 16px;
			text-transform: uppercase;
			color: #ffffff;
			padding-left: 15px;
			margin-bottom: 20px;
			line-height: 40px;
		}
		.posts-list{
			.item{
				width: 50%;
				float: left;
				margin-bottom: 20px;
				@include media-max($sm){
					width: 100%;
					float: none;
					margin-bottom: 15px;
				}
				.post-thumbnail{
					width: 100px;
					height: 100px;
					position: relative;
					display: inline-block;
					vertical-align: middle;
					margin-right: 15px;
					img{
						position: absolute;
						transform: translate3d(-50%,-50%,0);	
						top: 50%;
						left: 50%;
						border-radius: 50%;
						min-height: 100px;
					}
				}
				.post-title{
					display: inline-block;
					width: 65%;
					vertical-align: middle;
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}
	.site-footer{
		#footer-3{
			.footer-widget{
				h3{
					opacity: 0;
				}
			}	
		}
	}
	.footer-widget{
		h3{
			text-transform: uppercase;
			font-weight: 300;
		}
		ul{
			list-style: none;
			padding: 0;
			margin: 0;
			li{
				line-height: 30px;
			}
		}
	}
	.icon-social-footer{
		padding-top: 20px !important;
		li{
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background: #cccccc;
			display: inline-block;
			margin-right: 10px;
			text-align: center;
			&::before{
				font-size: 18px;
				color: #484848;
				line-height: 35px;
			}
			&:last-child{
				margin-right: 0;
			}
			a{
				font-size: 0;
			}
		}
	}
	.copyright{
		line-height: 30px;
	}
}