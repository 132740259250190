@import "../helpers/helpers";

// Web components.
@import "shortcodes/shortcodes";

div[data-animation-enable="1"] {
	opacity: 0;
}

div[data-animation-enable="1"].animated {
	opacity: 1;
}
