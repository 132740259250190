/*	[ Helper Classes ]
- - - - - - - - - - - - - - - - - - - - */
.mg0 {
  margin: 0;
}

.pd0 {
  padding: 0;
}

.mgt10 {
  margin-top: 10px;
}

.mgt20 {
  margin-top: 20px;
}

.mgt30 {
  margin-top: 30px;
}

.mgt40 {
  margin-top: 40px;
}

.mgt50 {
  margin-top: 50px;
}

.mgt60 {
  margin-top: 60px;
}

.mgt70 {
  margin-top: 70px;
}

.mgt80 {
  margin-top: 80px;
}

.mgt90 {
  margin-top: 90px;
}

.mgt100 {
  margin-top: 100px;
}

.mgb10 {
  margin-bottom: 10px;
}

.mgb20 {
  margin-bottom: 20px;
}

.mgb30 {
  margin-bottom: 30px;
}

.mgb40 {
  margin-bottom: 40px;
}

.mgb50 {
  margin-bottom: 50px;
}

.mgb60 {
  margin-bottom: 60px;
}

.mgb70 {
  margin-bottom: 70px;
}

.mgb80 {
  margin-bottom: 80px;
}

.mgb90 {
  margin-bottom: 90px;
}

.mgb100 {
  margin-bottom: 100px;
}

.mgtb10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mgtb20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mgtb30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mgtb40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mgtb50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mgtb60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mgtb70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mgtb80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mgtb90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mgtb100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mglr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mglr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mglr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mglr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mglr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mglr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mglr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mglr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mglr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mglr100 {
  margin-left: 100px;
  margin-right: 100px;
}

body:not(.rtl) .mgl10 {
  margin-left: 10px;
}

body:not(.rtl) .mgl20 {
  margin-left: 20px;
}

body:not(.rtl) .mgl30 {
  margin-left: 30px;
}

body:not(.rtl) .mgl40 {
  margin-left: 40px;
}

body:not(.rtl) .mgl50 {
  margin-left: 50px;
}

body:not(.rtl) .mgl60 {
  margin-left: 60px;
}

body:not(.rtl) .mgl70 {
  margin-left: 70px;
}

body:not(.rtl) .mgl80 {
  margin-left: 80px;
}

body:not(.rtl) .mgl90 {
  margin-left: 90px;
}

body:not(.rtl) .mgl100 {
  margin-left: 100px;
}

body:not(.rtl) .mgr10 {
  margin-right: 10px;
}

body:not(.rtl) .mgr20 {
  margin-right: 20px;
}

body:not(.rtl) .mgr30 {
  margin-right: 30px;
}

body:not(.rtl) .mgr40 {
  margin-right: 40px;
}

body:not(.rtl) .mgr50 {
  margin-right: 50px;
}

body:not(.rtl) .mgr60 {
  margin-right: 60px;
}

body:not(.rtl) .mgr70 {
  margin-right: 70px;
}

body:not(.rtl) .mgr80 {
  margin-right: 80px;
}

body:not(.rtl) .mgr90 {
  margin-right: 90px;
}

body:not(.rtl) .mgr100 {
  margin-right: 100px;
}

.pd10 {
  padding: 10px;
}

.pd20 {
  padding: 20px;
}

.pd30 {
  padding: 30px;
}

.pd40 {
  padding: 40px;
}

.pd50 {
  padding: 50px;
}

.pd60 {
  padding: 60px;
}

.pd70 {
  padding: 70px;
}

.pd80 {
  padding: 80px;
}

.pd90 {
  padding: 90px;
}

.pd100 {
  padding: 100px;
}

.pdt10 {
  padding-top: 10px;
}

.pdt20 {
  padding-top: 20px;
}

.pdt30 {
  padding-top: 30px;
}

.pdt40 {
  padding-top: 40px;
}

.pdt50 {
  padding-top: 50px;
}

.pdt60 {
  padding-top: 60px;
}

.pdt70 {
  padding-top: 70px;
}

.pdt80 {
  padding-top: 80px;
}

.pdt90 {
  padding-top: 90px;
}

.pdt100 {
  padding-top: 100px;
}

.pdb10 {
  padding-bottom: 10px;
}

.pdb20 {
  padding-bottom: 20px;
}

.pdb30 {
  padding-bottom: 30px;
}

.pdb40 {
  padding-bottom: 40px;
}

.pdb50 {
  padding-bottom: 50px;
}

.pdb60 {
  padding-bottom: 60px;
}

.pdb70 {
  padding-bottom: 70px;
}

.pdb80 {
  padding-bottom: 80px;
}

.pdb90 {
  padding-bottom: 90px;
}

.pdb100 {
  padding-bottom: 100px;
}

body:not(.rtl) .pdl10 {
  padding-left: 10px;
}

body:not(.rtl) .pdl20 {
  padding-left: 20px;
}

body:not(.rtl) .pdl30 {
  padding-left: 30px;
}

body:not(.rtl) .pdl40 {
  padding-left: 40px;
}

body:not(.rtl) .pdl50 {
  padding-left: 50px;
}

body:not(.rtl) .pdl60 {
  padding-left: 60px;
}

body:not(.rtl) .pdl70 {
  padding-left: 70px;
}

body:not(.rtl) .pdl80 {
  padding-left: 80px;
}

body:not(.rtl) .pdl90 {
  padding-left: 90px;
}

body:not(.rtl) .pdl100 {
  padding-left: 100px;
}

body:not(.rtl) .pdr10 {
  padding-right: 10px;
}

body:not(.rtl) .pdr20 {
  padding-right: 20px;
}

body:not(.rtl) .pdr30 {
  padding-right: 30px;
}

body:not(.rtl) .pdr40 {
  padding-right: 40px;
}

body:not(.rtl) .pdr50 {
  padding-right: 50px;
}

body:not(.rtl) .pdr60 {
  padding-right: 60px;
}

body:not(.rtl) .pdr70 {
  padding-right: 70px;
}

body:not(.rtl) .pdr80 {
  padding-right: 80px;
}

body:not(.rtl) .pdr90 {
  padding-right: 90px;
}

body:not(.rtl) .pdr100 {
  padding-right: 100px;
}

.hidden {
  display: none !important;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.dt {
  display: table;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.pf {
  position: fixed;
}

.oh {
  overflow: hidden;
}

.br-2 {
  border-radius: 2px;
}

.br-3 {
  border-radius: 3px;
}

.br-50 {
  border-radius: 50%;
}

.tu {
  text-transform: uppercase;
}

.fwb {
  font-weight: bold;
}

.bts-40 {
  width: 40px;
  text-align: center;
}

.bts-50 {
  width: 50px;
  text-align: center;
}

.bts-40,
.btr-40 {
  height: 40px;
  line-height: 40px;
}

.bts-50,
.btr-50 {
  height: 50px;
  line-height: 50px;
}

.clear,
.clearfix {
  @include clearfixall;
  clear: both;
}

.text-center{
	text-align: center;
}
.text-left{
	text-align: left;
}
.text-right{
	text-align: right;
}