.vmz_list_icon{
	display: flex;
	@include media-max($sm){
		display: block;
	}
	.icon{
		width: 20%;
		border: 1px solid $color_border;
		margin-right: 20px;
		text-align: center;
		padding: 29px 0;
		@include media-max($sm){
			width: 33.3%;
			float: left;
			margin-right: -1px;
			margin-bottom: -1px;
		}
		img{
			max-width: 100%;
			height: auto;
			padding-bottom: 7px;
			max-height: 54px;
		}
		p{
			margin: 0;
			font-size: 16px;
			color: #333333;
			@include media-max($sm){
				min-height: 48px;
			}
		}
	}
}