$content-width: 1170px !default;
$sidebar-width: 270px !default;
$gutter-width : 30px !default;

$header-height     : 60 !default;
$header-menu-height: 42 !default;
$header-m-height   : 42 !default;

$thumbnail : (
	height: 360,
	width : 480,
) !default;

$button: (
	padding: 20,
	radius : 3,
	border : 1,
) !default;

$typo: (
	body: (
		font-family: (Arial, sans-serif),
		font-size  : 14px,
		line-height: 1.48,
	),
	heading: (
		font-family: (Arial, sans-serif),
	),
	menu: (
		font-size  : 14,
	),
	submenu: (
		font-size  : 14,
		line-height: 40,
	),
	blockquote: (
		font-family: (Georgia, serif),
		font-size  : 18,
		font-style : italic,
		line-height: 1.45,
	),
	code         : (
		font-family: (Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace),
		font-size  : 14,
		line-height: 1.45,
	),
	button: (
		font-size     : 14,
		line-height   : 40px,
		letter-spacing: 0,
		text-transform: none,
		font-weight   : 400,
	),
	footer: (
		font-size     : 12,
		line-height   : 1.2,
	),
) !default;

$color: (
	bg: (
		primary   : #ffffff,
		secondary : #f2f2f2,
		field     : #f9f9f9,
		body-image: '',
	),
	main   : #ff4064,
	text   : #646464,
	heading: #323232,
	meta   : #ababab,
	border : #ebebeb,
	button : (
		primary: (
			background-color  : #333,
			color             : #fff,
			border-color      : #333,
			hover: (
				background-color: #fff,
				color           : #333,
				border-color    : #333,
			),
		),
		secondary: (
			background-color  : #333,
			color             : #fff,
			border-color      : #333,
			hover: (
				background-color: #fff,
				color           : #333,
				border-color    : #333,
			),
		),
	),
	top-bar: (
		bg        : #f2f2f2,
		text      : #646464,
		link      : #646464,
		link-hover: #ff4064,
	),
	footer: (
		bg        : #fff,
		bg-image  : null,
		text      : #646464,
		heading   : #333,
		link      : #646464,
		link-hover: #ff4064,
	),
	copyright: (
		bg        : #f2f2f2,
		text      : #646464,
		link      : #646464,
		link-hover: #ff4064,
	),
) !default;

// Grid system
// --------------------------------------------------
// Padding, to be divided by two and applied to the left and right of all columns
$grid-gutter-width: $gutter-width;

// Responsive Breakpoints
// ------------------------------------------------------

$xs:         480px !default;
$xs-min:     $xs !default;
$phone:      $xs-min !default;

// Small screen / tablet
$sm:         768px !default;
$sm-min:     $sm !default;
$tablet:     $sm-min !default;

// Medium screen / desktop
$md:         992px !default;
$md-min:     $md !default;
$desktop:    $md-min !default;

// Large screen / wide desktop
$lg:         1200px !default;
$lg-min:     $lg !default;
$lg-desktop: $lg-min !default;
$max-width:  $lg;

// So media queries don't overlap when required, provide a maximum
$xs-max:     ($sm-min - 1) !default;
$sm-max:     ($md-min - 1) !default;
$md-max:     ($lg-min - 1) !default;

// Modular scale settings for font size calculation
$modular-scale-ratio  : 1.23;
$modular-scale-base   : map-deep-get( $typo, body, font-size );

// Typography
$base-font-family     : map-deep-get( $typo, body, font-family );
$base-font-size       : map-deep-get( $typo, body, font-size );
$base-line-height     : map-deep-get($typo, body, line-height);


// Other Sizes
$base-border-radius   : 2px;
$base-spacing         : $base-line-height * 1em;
$small-spacing        : $base-spacing / 2;
$base-z-index         : 0;

// Color
$color-main           : map-deep-get( $color, main );
$color-text           : map-deep-get( $color, text );
$color-border         : map-deep-get($color, border);
$color-heading        : map-deep-get( $color, heading );

$bg-primary           : map-deep-get($color, bg, primary);
$bg-secondary         : map-deep-get($color, bg, secondary);

$base-border          : 1px solid $color-border;

// Animations
$base-duration        : 300ms;
$base-timing          : ease;
$base-transition      : all $base-duration $base-timing;

$base-box-shadow      : 1px 1px 3px rgba(0, 0, 0, 0.1);
$alt-box-shadow       : 0px 1px 10px rgba(0, 0, 0, 0.2);
$form-box-shadow      : inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 3px adjust-color(map-deep-get($color, main), $lightness: -5%, $alpha: -0.7);

$io : Ionicons;

