.style-title-border, .website-style-2{
	position: relative;
	&:after{
		content:"";
		position: absolute;
		left:0;
		bottom: 0;	
	}
	&.style-widget-home{
		color: #333333;
		margin-bottom: 45px;
		padding-bottom: 10px;
		font-size: 24px;
		text-transform: uppercase;
		@include media-max($xs){
			font-size: 18px;
			margin-bottom: 15px;
		}
		&:after{
			border-bottom: 1px solid $color-main;
			width: 150px;
			height: 1px;
		}
	}
	&.style-widget-sidebar, .website-style-2{
		color: #333333;
		margin-bottom: 30px;
		padding-bottom: 10px;
		font-size: 24px;
		text-transform: uppercase;
		@include media-max($xs){
			font-size: 18px;
			margin-bottom: 20px;
		}
		&:after{
			border-bottom: 2px solid $color-main;
			width: 60px;
			height: 2px;
		}
	}
}