/* Pagination */
.pagination {
	text-align: center;
	.nav-links {
		> * {
			width: 34px;
			height: 34px;
			line-height: 34px;
			display: inline-block;
			color: map-deep-get( $color, 'text' );
			@include border;
			border-color: transparent;
			&.current,
			&:hover {
				color: $color-main;
				@include border;
			}
		}
	}
}
.page-links {
	> *:not(.page-links-title) {
			width: 26px;
			height: 26px;
			line-height: 26px;
			text-align: center;
			display: inline-block;
			margin-left: $small-spacing/2;
			color: map-deep-get( $color, 'text' );
			@include border;
			border-color: transparent;
	}
	> span:not(.page-links-title),
	> a:hover {
			color: $color-main;
			@include border;
	}
}

/* Back to top */
.back-to-top {
	right         : $gutter-width/2;
	bottom        : 50px;
	position      : fixed;
	z-index       : 10;
	width         : 36px;
	height        : 36px;
	display       : block;
	pointer-events: none;
	opacity       : 0;
	text-align    : center;
	padding       : 0;
	line-height   : 36px;
	font-size     : 18px;
	background    : $bg-secondary;
	color         : $color-heading;
	border-radius : 0;
	transition    : $base-transition;
	@include border;
	&:focus {
		outline: none;
	}
	&:before {
		content    : "\f3d8";
		font-family: Ionicons;
	}
	&:hover {
		transition    : $base-transition;
		background-color: $color-main;
		border-color    : $color-main;
		color           : $bg-primary;
	}
	&.is-visible {
		opacity       : 1;
		transition    : $base-transition;
		pointer-events: visible;
	}
	&.fade-out {
		opacity       : 0.5;
		transition    : $base-transition;
		&:hover {
			opacity: 1;
		}
	}
}

#breadcrumbs {
	.insider {
		padding: $small-spacing/2 0;
		font-size: 12px;
	}
	#content & {
		margin-bottom: $base-spacing;
	}
	&.bg {
		.insider {
			padding-left: $small-spacing;
			padding-right: $small-spacing;
			background-color: $bg-secondary;
		}
		&.full-width {
			background-color: $bg-secondary;
			.insider {
				background-color: transparent;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&.border {
		.insider {
			border: $base-border;
			padding-left: $small-spacing;
			padding-right: $small-spacing;
		}
		&.full-width {
			border: $base-border;
			.insider {
				padding-left: 0;
				padding-right: 0;
				border: none;
			}
		}
	}
	&.box-shadow {
		.insider {
			box-shadow: $form-box-shadow;
		}
		&.full-width {
			box-shadow: $form-box-shadow;
			.insider {
				box-shadow: none;
			}
		}
	}
	.container {
		#content & {
			padding: 0;
		}
	}
}
