.list-item-home {
	width: 100%;
	clear: both;
	margin-bottom: 20px;
	overflow: hidden;
	.left-content {
		float: left;
		width: 42.5%;
		@media (max-width: 414px) {
			width: 100%;
			margin-bottom: 15px;
		}
		.img-home {
			margin-bottom: 10px;
			display: block;
			@media (max-width: 414px) {
				img {
					width: 100%;
				}
			}
		}
		.post-large-content {
			width: 100%;
			.title {
				margin-bottom: 10px;
				a {
					font-size: 16px;
					color: #333;
					display: block;
					line-height: 20px;
					font-weight: 600;
					&:hover {
						color: $color-main;
					}
				}
			}
			.excerpt {
				color: #666;
				font-size: 13px;
			}
		}
	}
	&.style-default {
		.left-content {
			.post-large-content {
				.excerpt {
					@media (max-width: 1024px) {
						display: none;
					}
				}
			}
		}
	}
	&.style-list{
		.item {
			width: 100%;
			float: left;
			margin-bottom: 65px;
			@include media-max($xs){
				margin-bottom: 15px;
			}
			.img-item {
				width: 39.6%;
				float: left;
				margin-right: 15px;
			}
			.content-item {
				width: 56%;
				float: left;
				.post-title {
					display: block;
					font-size: 16px;
					color: #333333;
					text-transform: uppercase;
					&:hover {
						color: $color-main;
					}
				}
				.excerpt {
					@include media-max($xs) {
						display: none;
					}
				}
			}
		}
	}
	&.style-onecolumn {
		.item {
			width: 100%;
			float: left;
			margin-bottom: 20px;
			.img-item {
				width: 25.6%;
				float: left;
				margin-right: 30px;
				@include media-max($sm) {
					margin-right: 15px;
				}
			}
			.content-item {
				width: 70%;
				float: left;
				.post-title {
					display: block;
					font-size: 16px;
					font-weight: bold;
					color: #333333;
					padding-bottom: 5px;
					&:hover {
						color: $color-main;
					}
				}
				.entry-meta {
					.post-categories {
						display: inline-block;
						margin-right: 10px;
						margin-bottom: 10px;
						padding: 0;
					}
				}
				.excerpt {
					@include media-max($xs) {
						display: none;
					}
				}
			}
		}
	}
	&.style-twocolumn {
		.row-column {
			overflow: hidden;
			width: 100%;
			margin-bottom: 20px;
			.item-twocolumn {
				float: left;
				width: 50%;
				.img-home {
					padding-bottom: 10px;
					display: block;
				}
				.title {
					a {
						color: #333;
						font-weight: 600;
						&:hover {
							color: $color-main;
						}
					}
				}
				&.item-1 {
					padding-right: 15px;
				}
				&.item-2 {
					padding-left: 15px;
				}
			}
		}
		.title-column {
			position: relative;
			padding-left: 15px;
			&:hover {
				&::before {
					color: $color-main;
				}
			}
			&::before {
				content: "\f24f";
				font-size: 6px;
				font-family: $io;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate3d(0, -50%, 0);
				color: #ccc;
			}
			a {
				color: #333;
				display: block;
				&:hover {
					color: $color-main;
				}
			}
		}
		.post-large-content {
			.excerpt {
				display: none;
				@media (max-width: 1024px) {
					display: block;
				}
			}
		}
	}
	&.style-threecolumn{
		.item{
			@include media-max($xs){
				width: 100%;
			}
		}
		.item-threecolumn{
			border: 1px solid #f2f2f2;
			@include media-max($xs){
				margin-bottom: 15px;
			}
			.img-home{
				margin-bottom: 15px;
				display: block;
				img{
					max-width: 100%;
					height: auto;
				}
			}
			.item-threecolumn-content{
				padding: 0 10px 70px 25px;
				@include media-max($xs){
					padding: 0 10px;
				}
				.title{
					text-algin: center;
					padding-bottom: 10px;
					a{
						color: $color-text;
						font-size: 18px;
					}
				}
				.excerpt{
					padding-bottom: 15px;
				}
				.poster-on{
					color: #cccccc;
					.view{
						padding-right: 7px;
						border-right: 1px solid #cccccc;	
					}
					.comment{
						padding-left: 7px;
						&.ion-chatboxes{
							&:before{
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}
	.right-content {
		float: left;
		width: 57.5%;
		padding-left: 30px;
		@media (max-width: 414px) {
			width: 100%;
			padding-left: 0;
		}
		.post-item {
			width: 100%;
			clear: both;
			overflow: hidden;
			margin-bottom: 15px;
			.img-home {
				float: left;
				display: inline-block;
				padding-right: 20px;
				width: 37.7%;
				@media (max-width: 414px) {
					width: 47%;
				}
			}
			.post-item-content {
				float: left;
				display: inline-block;
				width: 62.3%;
				@media (max-width: 414px) {
					width: 53%;
				}
				.title {
					a {
						font-weight: 600;
						color: #333;
						padding-bottom: 5px;
						line-height: 18px;
						@media (max-width: 320px) {
							font-size: 13px;
						}
						&:hover {
							color: $color-main;
						}
					}
				}
				.excerpt {
					color: #666;
					font-size: 13px;
					@media (max-width: 1024px) {
						display: none;
					}
				}
			}
		}
	}
}
