.website-style-2{
	&.home{
		background: #ffffff;
	}
	&:not(.home), &.home.blog{
		#content{
			padding-top: 0px;
			.row{
				#primary{
					.site-main{
						padding: 20px 9px;
						background: #ffffff;
						@include media-max($md){
							padding: 0;
						}
					}
				}	
			}
		}
	}
	#masthead{
		background: #ffffff;
	}
	.top-bar{
		line-height: 27px;
		height: 27px;
		border-bottom: $base-border;
		.right-content{
			ul{
				li{
					list-style: none;
					float: left;
					&:last-child{
						a{
							border-right: 0;
							padding-right: 0;
						}		
					}
					a{
						padding: 0 10px;
						border-right: $base-border;
					}
				}
			}
		}
	}
	.header-v1-alt{
		.right-hotline{
			height: 36px;
			line-height: 36px;
			background: #ff9900;
			color: #ffffff;
			text-transform: uppercase;
			text-align: center;
			width: 185px;
			&.ion-ios-telephone{
				&::before{
				margin-right: 5px;
				}
			}	
		}
		.container{
			position: relative;
			.search-box {
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translate3d(0,-50%,0);
				.ion-search{
					color: #ffffff;
					font-size: 18px;
					@include media-max($md) {
						
					}
				}
				input[type="text"] {
					border: 1px solid #ddd;
					border-radius: 1px;
					float: right;
					margin-top: 0;
					padding: 5px;
					background: #f9f9f9;
					margin-bottom: 0;
				}
				input:focus[type="text"]{
					box-shadow: none
				}
				input[type="submit"]{
					position: absolute;
					right: 8px;
					background: url("../images/search.png") no-repeat 8px 8px;
					background-color: $color_main;
					border: medium none;
					cursor: pointer;
					float: right;
					height: 36px;
					outline: medium none;
					width: 36px;
				}
				.des-search-form{
					background: #fff;
					position: absolute;
					top: 31px;
					right: 0;
					padding: 10px;
					z-index: 1;
					box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.2); 
					-webkit-box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.2);
					-moz-box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.2); 
					-o-box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.2);
					border: 1px solid #dedede;
					width: 300px;
					display: none;
					.input-group{
						overflow: hidden;
					}
				}
				.arrow-up{
					width: 0; 
					height: 0; 
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid #ff9900;
					height: 20px;
					position: absolute;
					top: -21px;
					right: 0px;
				}
			}
		}
	}
	.vietmoz-menu{
		>li{
			&.menu-item-has-children {
				&:after{
					content: "\f104";
				}
			}
		}
		ul{
			background: #e9ebe9;
			li{
				border-left: 4px solid transparent;
				background: rgba(#ffffff,0.1);
				border-bottom: 1px solid rgba(#000000, 0.2);
				a{
					text-transform: uppercase;
					border-bottom: 0;
					color: #333333;
				}
				&:hover{
					border-left: 4px solid #ff9900;
					a{
						background: rgba(#ffffff,0.1);
						border-bottom: 0;
					}
				}
			}
		}
	}
	&.archive, .page{
		.vietmoz-title{
			font-size: 48px;
			text-transform: uppercase;
			padding-bottom: 10px;
			margin-bottom: 30px;
			border-bottom: $base-border;
			@include media-max($xs){
				margin-bottom: 10px;
				font-size: 20px;
			}
		}
	}
	&.single{
		.vietmoz-title{
			font-size: 24px;
		}
	}
	&.archive{
		.loop-wrapper{
			article{
				padding-bottom: 24px;
				margin-bottom: 24px;
				border-bottom: $base-border;
				&:last-child{
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: 0;
				}
				.post-thumbnail{
					margin-bottom: 0;
				}
				.post-info{
					.post-title{
						font-size: 24px;
						margin-bottom: 5px;
					}
					.entry-meta{
						padding-bottom: 5px;
					}
					.post-excerpt{
						margin-bottom: 0;
					}
					.detail{
						float: right;	
						color: #ffae01;
					}
				}
			}
		}
		.img-custom{
			position: relative;
			margin-bottom: 45px;
			@include media-max($xs){
				margin-bottom: 15px;
			}
			.img-contact{
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
		.info-contact{
			font-size: 18px;
			color: #ff3333;
			text-align: center;
			padding: 25px 15px;
			border: 1px dashed $color_border;
			margin-bottom: 50px;
		}
		
		.layout-service{
			margin-bottom: 35px;	
			>h2{
				padding-left: 30px;
				position: relative;
				@include media-max($md){
					font-size: 18px;
					padding-left: 20px;
					line-height: 24px;
				}
				&:before{
					content: "\f2f6";
					color: #ff9900;
					font-family: $io;
					position: absolute;
					left:0;
					top: 0;
					@include media-max($md){
						font-size: 18px;
					}
				}
			}
		}
		.related-service{
			.heade-text{
				font-size: 24px;
				text-transform: uppercase;
				margin-bottom: 35px;
			}
			.list-item-cat{
				.item{
					.item-img{
						margin-bottom: 20px;
					}
					.item-title{
						font-size: 18px;
						text-transform: uppercase;
						color: #333333;
						&:hover{
							color: $color_main;
						}
					}
				}
			}
		}
	}
	.footer-sidebar{
		h3{
			text-transform: uppercase;
			font-size: 14px;
			margin-bottom: 20px;
			font-family: Roboto;
		}
		ul{
			list-style: none;
			padding: 0;
			margin: 0;
			li{
				padding-bottom: 15px;
			}
		}
	}
	.copyright{
		padding: 14px 0;
		.footer-logo{
			float: left;
			width: 82.9%;
			position: relative;
			&::after{
				content: "";
				position: absolute;
				right: 0px;
				top: 50%;
				border-bottom: 1px solid #097031;
				transform: translate3d(0, -50%, 0);
				height: 1px;
				width: 49%;
			}
		}
		.text-copyright{
			float: right;
			padding-top: 14px;
		}
	}
	.row-service{
		padding: 15px 0 50px;
		@include media-max($xs){
			padding: 0 0 15px;
		}
	}
	.row-img-right{
		padding: 15px 0 50px;
	}
	.pagination{
		text-align: right;
		.nav-links{
			.page-numbers{
				text-align: center;
				border: $base-border;
				margin: 0 5px;
				&.current, &:hover{
					background: #ffae01;
					border: 1px solid #ffae01;
					color: #ffffff;
				}
			}
		}
	}
	#secondary{
		.widget-title{
			position: relative;
			color: #333333;
			margin-bottom: 30px;
			padding-bottom: 10px;
			font-size: 24px;
			text-transform: uppercase;
			&:after{
				content:"";
				position: absolute;
				left:0;
				bottom: 0;	
				border-bottom: 2px solid $color-main;
				width: 60px;
				height: 2px;
			}
		}
	}
	#menu-dich-vu{
		list-style: none;
		padding: 0;
		margin: 0;
		li{
			padding: 10px 0;
			border-bottom: $base-border;
			&:first-child{
				padding-top:0;
			}
			&:last-child{
				padding-bottom: 0;
				border-bottom: 0;
			}
			a{
				position: relative;
				padding-left: 20px;
				display: block;
				color: #333333;
				&:hover{
					color: $color_main;
				}
				&:after{
					content: "\f362";
					font-family: $io;
					color: #ff9900;
					position: absolute;
					left:0;
					top: 50%;
					transform: translate3d(0,-50%,0);
					width: 20px;
				}
			}
		}
	}
	.vietmoz-related-posts {
		.posts-list{
			-webkit-column-count: 2; /* Chrome, Safari, Opera */
			-moz-column-count: 2; /* Firefox */
			column-count: 2;
			@include media-max($xs){
				-webkit-column-count: 1; /* Chrome, Safari, Opera */
				-moz-column-count: 1; /* Firefox */
				column-count: 1;
			}
			.item{
				position: relative;
				padding-left: 15px;
				padding-bottom: 10px;
				margin-bottom: 15px;
				border-bottom: $base-border;
				&:before{
					content:"";
					width: 6px;
					height: 6px;
					background: #ff9900;
					position: absolute;
					left: 0;
					top: 10px;
				}
				a{
					display: block;
					color: #666666;
					&:hover{
						color: $color_main;
					}
				}
			}
		}
	}
	.custom-contact{
		.ion-location, .ion-ios-telephone, .ion-email{
			&::before{
				color: #ff9900;
				margin-right: 10px;
			}
		}
	}
}
.title-custom{
	.head-contact{
		position: relative;
		color: #333333;
		margin-bottom: 30px;
		padding-bottom: 10px;
		font-size: 24px;
		text-transform: uppercase;
		&:after{
			content:"";
			position: absolute;
			left:0;
			bottom: 0;	
			border-bottom: 2px solid $color-main;
			width: 60px;
			height: 2px;
		}
	}
}
.info-contact{
	.title-custom{
		margin-bottom: 10px !important;
	}
	form{
		input[type="submit"]{
			font-size: 18px;
			font-family: Roboto Condensed;
			text-transform: uppercase;
		}
	}
}
.banner-icon{
	@include media-max($sm){
		display: none;
	}
}
.row-img-left{
	padding-bottom: 60px;
	@include media-max($xs){
		padding-bottom: 15px;
	}
}
.vc_column_container>.vc_column-inner{
	@include media-max($xs){
		padding-top: 10px !important;
	}
}