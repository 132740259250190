body {
	@include typo(map-deep-get($typo, body));
	color: $color-text;
	background-color: $bg-primary;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: map-deep-get( $typo, heading, font-family );
	line-height: 1.2;
	margin: 0 0 .75em;
	color: $color-heading;
	font-size: modular-scale( 1 );
	a {
		color: $color-heading;
	}
	&:hover a {
		color: $color-main;
	}
}

h3,
.h3 {
	font-size: modular-scale( 2 );
}

h2,
.h2 {
	font-size: modular-scale( 3 );
}

h1,
.h1 {
	font-size: modular-scale( 4 );
}

blockquote {
	@include typo(map-deep-get($typo, blockquote));
	margin     : 0.25em 0;
	padding    : 0.25em 40px;
	position   : relative;
	color      : #383838;
	p {
		margin: 0;
	}
	&:before {
		display: block;
		content: "\201C";
		font-size: 80px;
		position: absolute;
		left: -10px;
		top: -20px;
		color: #7a7a7a;
	}
}

cite {
	color: #999999;
	font-size: 14px;
	//display: block;
	margin-top: 5px;
	&:before {
		content: "\2014 \2009";
	}
}

// Table
table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th, td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
	text-align: left;
}

thead th {
  border-bottom: 1px solid shade($color-border, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

tr,
td,
th {
  vertical-align: middle;
}


// List
ul {
  list-style-type: none;
  margin: 0 0 $base-spacing;
  padding: 0;
	ul, ol {
		margin: 0;
	}
	.entry-content & {
		list-style-type: disc;
		padding-left: $base-spacing;
	}
}

ol {
  margin: 0 0 $base-spacing;
	padding: 0 0 0 $base-spacing;
	ul, ol {
		margin: 0;
	}
}

dl {
  margin: 0 0 $base-spacing;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

p {
	margin: 0 0 $small-spacing;
}

address {
	margin: 0 0 $small-spacing;
}

hr {
  border-bottom: $base-border;
  border-left  : 0;
  border-right : 0;
  border-top   : 0;
  margin       : $base-spacing 0;
}

a {
  color: $color-main;
  text-decoration: none;
  transition     : color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
		color: $color-heading;
  }
}

img {
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

abbr,
acronym {
	position: relative;
	&:after {
		position     : absolute;
		left         : 100%;
		top          : 0;
		display      : none;
		white-space  : nowrap;
		padding      : 0 $small-spacing;
		background   : map-deep-get($color, main);
		color        : #fff;
		content      : attr(title);
		border-radius: $base-border-radius;
		transform    : translateX(7px);
		box-shadow   : $base-box-shadow;
	}
	&:before {
		display      : none;
		content      : '';
		position     : absolute;
		left         : 100%;
		border-right : 4px solid map-deep-get($color, main);
		border-top   : 4px solid transparent;
		border-bottom: 4px solid transparent;
		transform    : translate3d(3px, 8px, 0px);
		top          : 0;
	}
	&:hover:after,
	&:hover:before {
		display      : block;
	}
}

// Inline code
code,
kbd,
var,
samp {
  padding: 2px 3px;
	@include typo(map-deep-get($typo, code));
  background-color: map-deep-get($color, bg, secondary);
	border-radius: $base-border-radius;
	box-shadow: $form-box-shadow;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    padding: 0;
    color: inherit;
    background-color: inherit;
  }
  kbd {
    padding: 0;
	}
}

// Blocks of code
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
	@include typo(map-deep-get($typo, code));
  background-color: map-deep-get($color, bg, secondary);
	padding: $small-spacing;
	border-radius: $base-border-radius;
	box-shadow: $form-box-shadow;
	white-space: pre-wrap;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0;
		box-shadow: none;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: 400px;
  overflow-y: scroll;
}
