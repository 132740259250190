fieldset {
  background-color: transparent;
  border          : 0;
  margin          : 0;
  padding         : 0;
}

legend {
  font-weight  : 600;
  margin-bottom: $small-spacing / 2;
  padding      : 0;
}

label {
  display      : block;
  font-weight  : 600;
  margin-bottom: $small-spacing / 2;
}

#{$all-text-inputs} {
	@include typo( map-deep-get($typo, body) );
  display         : block;
  appearance      : none;
  background-color: map-deep-get($color, bg, field);
  border          : $base-border;
  border-radius   : $base-border-radius;
  box-shadow      : $form-box-shadow;
  box-sizing      : border-box;
  margin-bottom   : $small-spacing;
  padding         : $base-spacing / 3;
  transition      : border-color $base-duration $base-timing;
  width           : 100%;

  &:hover {
    border-color: shade($color-border, 20%);
  }

  &:focus {
    border-color: rgba($color-main, 0.5);
    box-shadow  : $form-box-shadow-focus;
    outline     : none;
  }

  &:disabled {
    background-color: shade($bg-primary, 5%);
    cursor          : not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint(map-deep-get($color, text), 40%);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display     : inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width        : 100%;
}

select {
  margin-bottom: $small-spacing;
  width        : 100%;
}
