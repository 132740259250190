.vmz_listcat{
	.list-cat-shortcode{
		.item{
			@include media-max($xs){
				width: 100%;
			}
		}
	}
	.item-cat{
		background: #ffffff;
		border: $base-border;
		margin-bottom: 20px;
		@include media-max($xs){
			margin-bottom: 10px;
		}
		.img-item{
			margin-bottom: 20px;
			@include media-max($xs){
				margin-bottom: 10px;
			}
			img{
				max-width: 100%;
				height: auto;
				@include media-max($xs){
					width: 100%;
				}
			}
		}
		.content-item{
			.cat-title{
				font-size: 18px;
				font-weight: bold;
				text-transform: uppercase;
				color: #333333;
				padding-bottom: 15px;
				display: block;
				text-align: center;
			}
			.excerpt{
				padding: 0 10px 30px 15px;
				@include media-max($xs){
					padding: 0 10px 10px;
				}
			}
		}
		.detail-cat{
			text-align: center;
			margin-bottom: 35px;
			@include media-max($xs){
				margin-bottom: 20px;
			}
			a{
				background: $color-main;
				color: #ffffff;
				font-size: 16px;
				width: 126px;
				height: 42px;
				display: block;
				text-align: center;
				line-height: 42px;
				margin: auto;
				border: 1px solid transparent;
				border-radius: 5px;
				&:hover{
					background: #04662b;
				}
			}
		}
	}
}
