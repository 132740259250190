@mixin clearfixall() {
	&:before, &:after {
		clear  : both;
		content: '';
		display: table;
	}
}
@mixin print($declarations) {
	@each $property, $value in $declarations {
		#{$property}: $value
	}
}

@mixin typo( $map: null, $addition :null ) {
	@if type-of( $map ) == map {
		@if type-of( $addition ) == map {
			$map: map-merge($map, $addition)
		}
		@each $property, $value in $map {
			@if $property == font-size {
				#{$property}: px($value)
			} @else if $property == line-height {
				@if $value > 10 {
					#{$property}: px($value)
				} @else {
					#{$property}: $value;
				}
			} @else {
				#{$property}: $value;
			}
		}

	}
}

@mixin hover {
  // TODO: re-enable along with mq4-hover-shim
//  @if $enable-hover-media-query {
//    // See Media Queries Level 4: https://drafts.csswg.org/mediaqueries/#hover
//    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
//    @media (hover: hover) {
//      &:hover { @content }
//    }
//  }
//  @else {
    &:hover { @content }
//  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus { @content }
    @include hover { @content }
  }
  @else {
    &:focus,
    &:hover {
      @content
    }
  }
}

@mixin plain-hover-focus {
  @if $enable-hover-media-query {
    &,
    &:focus {
      @content
    }
    @include hover { @content }
  }
  @else {
    &,
    &:focus,
    &:hover {
      @content
    }
  }
}

@mixin hover-focus-active {
  @if $enable-hover-media-query {
    &:focus,
    &:active {
      @content
    }
    @include hover { @content }
  }
  @else {
    &:focus,
    &:active,
    &:hover {
      @content
    }
  }
}

@mixin button-color($map) {
	@each $property, $value in $map {
		@if $property != hover {
			#{$property}: $value;
		}
	}
}

@mixin media-max( $break-point ) {
	@include media( max-width ( $break-point - 1 ) ) {
		@content;
	}
}

@mixin align( $option ) {
	@if $option == "rc" {
		position : absolute;
		right    : 0;
		top      : 50%;
		transform: translateY(-50%);
	} @else if $option == "lc" {
		position : absolute;
		left     : 0;
		top      : 50%;
		transform: translateY(-50%);
	} @else if $option == "ct" {
		position : absolute;
		top      : 0;
		left     : 50%;
		transform: translateX(-50%);
	} @else {
		position : absolute;
		left     : 50%;
		top      : 50%;
		transform: translate3d(-50%, -50%, 0);
	}
}

@mixin media($query: $feature $value) {
  @if length($query) == 1 {
    @media screen and (min-width: nth($query, 1)) {
      @content;
    }
  } @else {
    $loop-to: length($query);
    $media-query: "screen and ";

    @if is-not(is-even(length($query))) {
      $loop-to: $loop-to - 1;
    }

    $i: 1;
    @while $i <= $loop-to {
      $media-query: $media-query + "(" + nth($query, $i) + ": " + nth($query, $i + 1) + ") ";

      @if ($i + 1) != $loop-to {
        $media-query: $media-query + "and ";
      }

      $i: $i + 2;
    }

    @media #{$media-query} {
      @content;
    }
  }
}

@mixin border( $sides: null ) {
  @if $sides == null {
		border: $base-border;
	} @else {
    $loop-to: length($sides);

    $i: 1;
    @while $i <= $loop-to {
			border-#{nth($sides, $i)}: $base-border;
      $i: $i + 1;
    }
	}
}

@mixin radius() {
	border-radius: $base-border-radius;
}
