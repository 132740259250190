/* Grid */

.grid-item {
	width: 100%;
	margin-bottom: $base-spacing;
	> * {
		margin: 0;
		& + * {
			margin-top: $small-spacing;
		}
	}
}

.grid {
	margin-right: -$gutter-width/2;
	margin-left: -$gutter-width/2;
	.grid-item {
		padding: 0 $gutter-width/2;
	}
	&.column-2 {
		.grid-item {
			width: 50%;
			@include media-max($sm) {
				width: 49.9%;
			}
			@include media-max($xs) {
				width: 100%;
			}
		}
	}
	&.column-3 {
		.grid-item {
			width: 33.3%;
			@include media-max(666px) {
				width: 49.9%;
			}
			@include media-max($xs) {
				width: 100%;
			}
		}
	}
	&.column-4 {
		.grid-item {
			width: 25%;
			@include media-max($md) {
				width: 33.3%;
			}
			@include media-max(666px) {
				width: 49.9%;
			}
			@include media-max($xs) {
				width: 100%;
			}
		}
	}
}

.post-title {
	hyphens: auto;
}

.post-thumbnail {
	a,
	img {
		display: block;
	}
}

.sticky {
	@include border;
	@include padding($small-spacing);
	.medium & {
		padding-bottom: 0;
	}
	.grid & {
		border: none;
	}
}

.loop-wrapper.archive-style-1 {
	article {
		@include clearfixall;
	}
	.post-thumbnail {
		width: 31%;
		float: left;
		margin-right: $base-spacing;
		margin-bottom: $small-spacing;
		@include media-max($xs) {
			width: 100%;
			float: none;
			margin-right: 0;
			margin-bottom: 0;
		}
		img{
			@include media-max($xs) {
				width: 100%;
			}
		}
	}
	.post-info {
		width: calc(69% - #{$base-spacing});
		float: left;
		margin-top: 0;
		@include media-max($xs) {
			width: 100%;
			float: none;
			margin-top: $small-spacing;
		}
	}

	.post-title {
		margin-top: 0;
		font-size: 14px;
		margin-bottom: 20px;
		@include media-max($xs) {
			margin-top: $small-spacing;
		}
	}
}

