// Widget
#secondary {
	@include media-max($md) {
		padding-top: $base-spacing;
	}
	.widget {
		width: 100%;
		@include media(min-width $xs max-width $md) {
			width: 48%;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.gutter-sizer {
		width: 4%;
	}
}

.widget {
	margin-bottom: $base-spacing;
	.widget-title {
		font-size: modular-scale(1);
	}
	ul {
		margin: 0;
		list-style: square;
		padding-left: 15px;
	}
	ul,
	.tagcloud {
		a {
			color: $color-text;
			&:hover {
				color: $color-main;
			}
		}
	}
	.widget-background & {
		background-color: map-deep-get($color, bg, secondary);
		padding: $small-spacing;
	}
	.widget-border & {
		border: $base-border;
		padding: $small-spacing;
	}
	.widget-divider & {
		.widget-title {
			border-bottom: $base-border;
			padding-bottom: $small-spacing;
			margin-bottom: $small-spacing;
		}
	}
}

// Search form.
.search-form {
	position: relative;
	.search-field {
		margin-bottom: 0;
		border-radius: 0;
	}
	.search-submit {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		line-height: 1;
		border-radius: 0;
	}
	.vietmoz-search-icon {
		&:before {
			content: "\f4a4";
			font-family: "Ionicons";
			font-size: 18px;
		}
	}
}

#wp-calendar {
	width: 100%;
	caption {
		text-align: right;
		color: $color-main;
		font-size: 12px;
		padding: 5px 0;
	}
	thead {
		font-size: 10px;
		th {
			padding-bottom: 10px;
			text-align: center;
		}
	}
	tbody {
		color: $color-text;
		td {
			background: $bg-secondary;
			border: 1px solid $bg-primary;
			text-align: center;
			padding: 6px;
			&:hover {
				background: $bg-primary;
			}
		}
		.pad {
			background: none;
		}
	}
	tfoot {
		#next {
			font-size: 10px;
			text-transform: uppercase;
			text-align: right;
		}
		#prev {
			font-size: 10px;
			text-transform: uppercase;
			padding-top: 10px;
		}
	}
}

.widget-style-1 {
	.sidebar-widget {
		.widget-title {
			background: #efefef;
			border-top: 2px solid $color-main;
			font-size: 18px;
			color: $color-main;
			padding-left: 19px;
			line-height: 32px;
			border-radius: 5px 5px 0 0;
			margin-bottom: 0;
		}
	}
}
/* widget list in cat in layout style Cat Service */

.list-post-in-cat{
	.list-posts{
		li{
			padding: 10px 0;
			border-bottom: $base-border;
			&:first-child{
				padding-top:0;
			}
			&:last-child{
				padding-bottom: 0;
				border-bottom: 0;
			}
			a{
				position: relative;
				padding-left: 20px;
				display: block;
				color: #333333;
				&:hover{
					color: $color_main;
				}
				&:after{
					content: "\f362";
					font-family: $io;
					color: #ff9900;
					position: absolute;
					left:0;
					top: 50%;
					transform: translate3d(0,-50%,0);
					width: 20px;
				}
			}
		}
	}
}
.hotline-contact{
	background: $color_main;
	color: #ffffff;
	padding: 5px 0;
	margin-bottom: 25px;
	.text-head{
		text-align: center;
		span{
			border-bottom: 1px solid #ffffff;
			margin-bottom: 5px;
			display: inline-block;
			&.ion-ios-telephone{
				&:before{
					padding-right: 10px;
				}
			}
		}
	}
	
	.hotline{
		font-size: 24px;
		text-align: center;
	}
}
.list-img-action{
	ul{
		li{
			width: 50%;
			float: left;
			margin-bottom: -1px;
			img{
				width: 100%;
				height: auto;
			}
		}
	}
}
/* widget list posts tab */
.list-posts-tab{
	.post-tab-titles{
		padding: 0;
		margin: 0;
		list-style: none;
		li{
			float: left;
			width: 50%;
			background: $color_main;
			text-align: center;
			line-height: 40px;	
			&:first-child{
				border-right: 1px solid rgba(#ffffff, 0.5);
			}
			&:hover{
				background: #333333;	
				a{
					color: #ffffff;
				}
			}
			a{
				color: #ffffff;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
	.tab-contents{
		.post-tab-content{
			display: none;
			&.post-tab-show{
				display: block;
			}
			.list-posts{
				list-style: none;
				padding: 0;
				margin: 0;
				padding-top: 60px;
				@include media-max($sm){
					padding-top: 50px;
				}
				.post-tab{
					clear: both;
					margin-bottom: 30px;
					overflow: hidden;
					@include media-max($sm){
						margin-bottom: 20px;
					}
					.img-item{
						overflow: hidden;
						border-radius: 50%;
						width: 100px;
						height: 100px;
						display: inline-block;
						vertical-align: middle;
						margin-right: 15px;
						img{
							max-width: 100px;
							max-height: 100px;
						}
					}
					.title-post{
						display: inline-block;
						width: 65%;
						vertical-align: middle;
						a{
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}