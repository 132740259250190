body:not(.home),
body.home.blog {
	#content {
		padding-top: $base-spacing;
		padding-bottom: $base-spacing;
	}
}

body.vc-enabled #content {
	padding-top: 0;
	padding-bottom: 0;
}

#content > .row {
	margin-left: 0;
	margin-right: 0;
}

#primary,
#secondary,
.entry-content {
	@include clearfixall;
}

@include media($md) {
	#primary {
		width: calc(100% - #{$sidebar-width + $gutter-width});
		float: left;
		.sidebar-left & {
			float: right;
		}
		.sidebar-none & {
			width: 100%;
			float: none;
		}
	}
	#secondary {
		width: $sidebar-width;
		float: right;
		.sidebar-left & {
			float: left;
		}
		.sidebar-none & {
			display: none;
		}
	}
}

#{$all-buttons},
.button,
.btn {
  appearance: none;
	@include button-color( map-deep-get($color, button, primary) );
	@include typo(map-deep-get($typo, button));
	padding: 0 px(map-deep-get($button, padding));
	border-radius: px(map-deep-get($button, radius));
	border-width: px(map-deep-get($button, border));
	border-style: solid;
  cursor: pointer;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  text-decoration: none;
  transition: all $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
		@include button-color( map-deep-get($color, button, primary, hover) );
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
			@include button-color( map-deep-get($color, button, primary, hover) );
    }
  }
	&.alt {
		@include button-color( map-deep-get($color, button, secondary) );

		&:hover,
		&:focus {
			@include button-color( map-deep-get($color, button, secondary, hover) );
		}

		&:disabled {
			&:hover {
				@include button-color( map-deep-get($color, button, secondary, hover) );
			}
		}
	}
}

twitterwidget,
embed,
iframe {
	max-width: 100%;
}
