/* Single */
.single #main > article {
	clear: both;
	@include clearfixall;
	> *:last-child {
		margin-bottom: 0;
	}
	padding-bottom: $small-spacing;
	margin-bottom: $small-spacing;
}

.entry-header {
	margin-bottom: $small-spacing;
	padding-bottom: $small-spacing;
	> * {
		margin: 0;
		& + * {
			margin-top: $small-spacing;
		}
	}
}

.entry-title {
	hyphens: auto;
}

.entry-meta {
	color: map-deep-get($color, meta);
	font-size: 13px;
	a {
		color: map-deep-get($color, meta);
		&:hover {
			color: $color-main;
		}
	}
	time.updated {
		display: none;
		&.published {
			display: inline;
		}
	}
}

.entry-content {
	margin-bottom: $base-spacing;
}

.entry-footer {
	margin-bottom: $base-spacing;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-top: -$base-spacing;
	> * {
		margin: $base-spacing 0 0;
	}
}

.tags-list {
	a {
		background: $bg-secondary;
		font-size: 11px;
		padding: 2px 5px;
		color: $color-text;
		white-space: nowrap;
		&:hover {
			background-color: shade($bg-secondary, 5%);
		}
	}
	.divider {
		opacity: 0;
	}
}

.social-sharing {
	@include clearfixall;
	li {
		display: block;
		float: left;
		margin-right: 10px;
		span {
			display: none;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	a {
		display: block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 20px;
		transition: all 1s;
		border: 1px solid;
		&:hover {
			transition: all 1s;
		}
	}
	&.classic {
		li {
			margin: 0;
		}
	}
}

.author-box {
	border: $base-border;
	padding: $small-spacing;
	background: $bg-secondary;
	width: 100%;
	@include clearfix;
	.avatar-wrap {
		float: left;
		display: block;
		margin-right: $base-spacing;
	}
	.author-name {
		color: $color-text;
		font-size: modular-scale(2);
		font-weight: bold;
		text-transform: uppercase;
		&:hover {
			color: $color-main;
		}
	}
}

.vietmoz-related-posts {
	margin-bottom: $base-spacing;
}

/* Comment */
.comments-title,
.comment-reply-title {
	font-size: modular-scale(1);
}

.comment-body {
	@include border(bottom);
	margin-bottom: $base-spacing;
	padding-bottom: $base-spacing;
	position: relative;
	.bypostauthor & {
		border-color: $color-main;
	}
	.reply a {
		position: absolute;
		top: 0;
		right: 0;
		text-transform: uppercase;
		color: $color-text;
		&:hover {
			color: $color-main;
		}
	}
}

#cancel-comment-reply-link {
	@include border;
	@include radius;
	@include padding(3px $small-spacing/2);
	color: $color-text;
	font-size: modular-scale(-1);
	vertical-align: text-top;
	&:hover {
		color: $color-main;
		border-color: $color-main;
	}
}

.comment-meta {
	@include clearfixall;
	margin-bottom: $small-spacing;
}

.comment-author {
	color: $color-text;
	a {
		color: $color-text;
		&:hover {
			color: $color-main;
		}
	}
}

.comment-metadata {
	color: map-deep-get($color, meta);
	font-size: modular-scale(-1);
	a {
		color: map-deep-get($color, meta);
		&:hover {
			color: $color-heading;
		}
	}
}

.comment-content {
	> p:last-child {
		margin-bottom: 0;
	}
	.children & {
		padding-left: $base-spacing;
	}
}

.comment-list {
	padding: 0;
	list-style: none;
	.avatar {
		width: 75px;
		height: auto;
		float: left;
		margin-right: $small-spacing;
	}
	.children {
		list-style: none;
		.avatar {
			width: 40px;
		}
	}
}

#respond {
	margin-bottom: $base-spacing;
}

.comment-form-author,
.comment-form-email,
.comment-form-url {
	width: 32%;
	margin-right: 2%;
	float: left;
}
.comment-form-url {
	margin-right: 0;
}

/* Page */
.page-header,
.page-content,
.page-footer {
	margin-bottom: $base-spacing;
}

.page-content {
	@include clearfixall;
}

.page-template-page-full-width {
	#primary {
		width: 100%;
	}
}
