#colophon {
	@include typo(map-deep-get($typo, footer));
	.footer-sidebar {
		@include make-xs-column( 12 );
	}
	.grid-sizer {
		width: 1%;
	}
}
#colophon .footer-sidebar {
	@include media( min-width $xs max-width $md ) {
		width: calc(50% - 1px);
	}
	@include span-md-columns( 3 );
	.footer-layout-1 & {
		@include span-md-columns( 12 );
	}
	.footer-layout-2 & {
		@include span-md-columns( 9 );
		&#footer-2 {
			@include span-md-columns( 3 );
		}
	}
	.footer-layout-3 & {
		@include span-md-columns( 6 );
	}
	.footer-layout-4 & {
		@include span-md-columns( 3 );
		&#footer-2 {
			@include span-md-columns( 9 );
		}
	}
	.footer-layout-5 & {
		@include span-md-columns( 4 );
		&#footer-2 {
			@include span-md-columns( 8 );
		}
	}
	.footer-layout-6 & {
		@include span-md-columns( 4 );
	}
	.footer-layout-7 & {
		@include span-md-columns( 3 );
		&#footer-2 {
			@include span-md-columns( 6 );
		}
	}
	.footer-layout-8 & {
		@include span-md-columns( 3 );
		&#footer-1 {
			@include span-md-columns( 6 );
		}
	}
	.footer-layout-9 & {
		@include span-md-columns( 3 );
		&#footer-3 {
			@include span-md-columns( 6 );
		}
	}
	.footer-layout-11 & {
		@include span-md-columns( 2 );
		&#footer-1 {
			@include span-md-columns( 4 );
		}
	}
	.footer-layout-12 & {
		@include span-md-columns( 2 );
		&#footer-5 {
			@include span-md-columns( 4 );
		}
	}
}

#colophon {
	background-color: map-deep-get( $color, footer, bg );
	@if map-deep-get( $color, footer, bg-image ) {
		background-image: url( map-deep-get( $color, footer, bg-image ) );
	}
	color: map-deep-get( $color, footer, text );
	padding: $base-spacing 0 0;
	h1, h2, h3, h4, h5, h6 {
		color: map-deep-get( $color, footer, heading );
	}
	a {
		color: map-deep-get( $color, footer, link );
		&:hover,
		&:focus {
			color: map-deep-get( $color, footer, link-hover );
		}
	}
	ul,
	p:last-child {
		margin-bottom: 0;
	}
}

.copyright {
	@include typo(map-deep-get($typo, footer));
	padding: $base-spacing/3 0;
	background-color: map-deep-get( $color, copyright, bg );
	color: map-deep-get( $color, copyright, text );
	a {
		color: map-deep-get( $color, copyright, link );
		&:hover,
		&:focus {
			color: map-deep-get( $color, copyright, link-hover );
		}
	}
}
